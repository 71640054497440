.upload-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #0A0F1B 0%, #1B3A57 50%, #112237 100%); 
  border-radius: 20px;
  padding: 20px;
  color: #FFFFFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  width: 100%;
  text-align: center;
  box-sizing: border-box; /* Asegura que el padding esté incluido en el ancho total */
  margin-right: 10px;
}
.convert-text {
  font-size: 24px;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.upload-form {
  width: 100%; /* Asegura que el formulario ocupe todo el ancho del contenedor */
  box-sizing: border-box; /* Asegura que el padding esté incluido en el ancho total */
  background-color: transparent; /* Fondo transparente */
}

.upload-form input[type="file"] {
  display: none;
}

/* Unificar estilos de botones y el campo de texto */
.file-label,
.upload-form button,
.upload-form input[type="email"] {
  background-color: #008CBA;
  color: #FFFFFF;
  border: none;
  padding: 13px 25px; /* Aumentar padding del botón */
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 40px;
  width: 100%;
  box-sizing: border-box; /* Para asegurarse de que el padding se incluya en el ancho total */
  text-align: center;
  display: inline-block; /* Asegura que el label se comporte como un botón */
  margin-bottom: 20px;
}

.file-label:hover,
.upload-form button:hover {
  background-color: #005F73;
}

.upload-form input[type="email"] {
  background-color: #FFFFFF;
  color: #333;
  border: 1px solid #00ADEF;
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.modal-title {
  color: #333;
}

.progress-bar {
  width: 100%;
  background: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 20px 0;
}

.progress {
  height: 20px;
  background: #76c7c0;
  width: 0;
  transition: width 0.4s ease;
}

.message {
  text-align: center;
}

.success-image {
  max-width: 300px;
  margin: 20px 0;
}

.message p {
  margin-top: 10px;
  font-size: 1.2em;
  color: #333;
}

.message button {
  background: #76c7c0;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 10px;
}

.message button:hover {
  background: #5baaa8;
}
