body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #0A0F1B;
  color: #FFFFFF;
  background-image: url('./assets/fondo1.webp'); /* Ruta a la imagen del fondo */
  background-size: contain;
  background-repeat: repeat;
  background-attachment: fixed; /* Para que el fondo no se desplace */
  background-position: center top; /* Centrar el fondo */
}

body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.55); /* Ajusta el color y la opacidad aquí */
  z-index: -1; /* Asegura que la superposición esté detrás del contenido */
  pointer-events: none; /* Permite interactuar con el contenido a través de la superposición */
}

header {
  background-color: #0A0F1B;
  width: 100%; /* Ajustar a 100% para cubrir todo el ancho */
  padding: 0;
  margin: 0 auto;
  position: fixed; /* Fijar la barra superior */
  top: 0;
  z-index: 1000; /* Asegurar que esté por encima de otros elementos */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Añadir sombra para mejorar la visibilidad */
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px; 
  padding-right: 40px; 
}

.navbar-title {
  font-size: 2.5em;
  color: #FFFFFF;
  margin: 0;
  margin-left: 20px;
}
.navbar-buttons {
  display: flex;
  justify-content: flex-end; /* Alinea los botones a la derecha */
  margin-right: 40px; /* Ajusta el margen derecho */
}
.navbar-button {
  background-color: #FFFFFF; /* Cambia el color de fondo a blanco */
  color: #000000; /* Cambia el color del texto a negro */
  border: none;
  padding: 10px 20px;
  margin: 5px;
  text-decoration: none;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.navbar-button:hover {
  background-color: #DDDDDD; /* Un color ligeramente diferente al pasar el cursor */
}

.container {
  display: flex;
  justify-content: space-between; /* Distribuir espacio entre las columnas */
  align-items: center;
  padding: 20px;
  position: relative;
  padding-top: 60px; /* Añadir padding superior */
  width: 100%;
  height: calc(100vh - 60px); /* Altura mínima del contenedor */
  box-sizing: border-box; /* Incluir el padding en el tamaño total */
}

.text-column {
  flex: 0 1 30%; /* Cambiar ancho al 30% */
  text-align: left;
  margin-right: 10px;
  margin-left: 10px; 
  
}

.image-column {
  flex: 0 1 40%; /* Cambiar ancho al 40% */
  display: flex;
  justify-content: center;
}

.form-column {
  flex: 0 1 30%; /* Cambiar ancho al 30% */
  display: flex;
  justify-content: center;
}

.main-text {
  font-size: 2.2em;
  font-weight: bold;
  color: #00ADEF;
  margin: 0;
  
}

.sub-text {
  font-size: 1.1em;
  color: #FFFFFF;
  text-align: justify;
}

.description {
  text-align: center; /* Centrar el texto */
  margin-top: 20px; /* Añadir margen superior para separarlo de los elementos anteriores */
}

.App-logo {
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 90%;
  alt: "Herramienta de transcripción de audio y video con inteligencia artificial";
}

.upload-form {
  width: 100%; /* Asegurando que el formulario ocupe todo el ancho del contenedor */
  background-color: transparent; /* Fondo transparente */
}

.upload-form div {
  margin: 15px 0; /* Aumentar margen entre los elementos */
}

.upload-form input[type="email"] {
  background-color: #FFFFFF;
  color: #333;
  border: 1px solid #00ADEF;
}

.upload-form input[type="file"] + label:hover,
.upload-form button:hover {
  background-color: #005F73;
}

@media (max-width: 600px) {
  .container {
    flex-direction: column; /* Cambiar a columna en pantallas pequeñas */
  }

  .text-column,
  .image-column,
  .form-column {
    max-width: 100%;
    margin: 10px 0;
  }

  .App-logo {
    max-width: 100%;
    margin: 0 0 20px 0;
    order: 1; /* Mantener la imagen debajo del formulario en pantallas pequeñas */
  }

  h1 {
    font-size: 2em;
  }

  .upload-form-container {
    top: 60px; /* Ajustar para que el formulario no quede oculto debajo de la barra superior */
    transform: none; /* Eliminar la transformación en pantallas pequeñas */
  }

  .upload-form {
    width: 90%;
    order: 2; /* Mantener el formulario encima de la imagen en pantallas pequeñas */
  }

  .upload-form input[type="email"] {
    width: 100%;
  }
}
